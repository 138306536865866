/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { ArrowSmRightIcon, CheckIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Conseil aux entreprises sur les procédures d’instruction AT/MP',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 2,
    name: 'Audit des comptes employeurs AT/MP',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 3,
    name: 'Conseil aux entreprises sur les procédures de vérifications URSSAF',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 4,
    name: 'Gestion des relations avec les organismes de sécurité sociale',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 5,
    name: 'Gestion de la tarification des cotisations sociales',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 6,
    name: 'Recours amiables et contentieux (Commission de recours amiable, pôle social du tribunal judiciaire) - Contentieux Technique médical (révision des taux d’IPP)',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 7,
    name: 'Contentieux de la faute inexcusable',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 8,
    name: 'Contentieux de la tarification (CARSAT, chambre de la tarification de la Cour d’appel d’AMIENS) ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 9,
    name: 'Contentieux Pénal de l’accident de travail ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'Inaptitude',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 2,
    name: 'Relations avec les médecins du travail',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 3,
    name: 'Licenciements individuels et collectifs',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 4,
    name: 'Rédaction des contrats de travail',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 5,
    name: 'Assistance à la gestion des Relations collectives',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 6,
    name: 'Contentieux Prud’homal',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 7,
    name: 'Droit pénal du travail',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
]

export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
            width={404}
            fill="none"
            viewBox="0 0 404 4000"
            aria-hidden="true"
          >
          </svg>

        <div className="relative">
        <h1 className="mb-8 text-left text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Les activités
          </h1>

          <h2 id="droit" className="text-left text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
          Droit social
          </h2>
          <p className="mt-4 text-lg text-gray-500 text-justify">
          Le cabinet Céos Avocats a fait du Droit Social, et plus particulièrement de la Sécurité et de la Santé au travail, son cœur d’expertise juridique, et assiste notamment des grands groupes du BTP dans la mise en œuvre de la législation sur la santé et la sécurité au travail, et, dans son corollaire, la gestion des cotisations de sécurité sociale. 
          <br /><br />
          Les interventions du cabinet Céos Avocats se déploient ainsi dans les deux branches du Droit Social :
          </p>
        </div>

        <div className="relative mt-4 lg:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-1xl font-extrabold text-gray-900 tracking-tight sm:text-2xl">
            Le droit de la sécurité sociale
            </h3>

            <dl className="mt-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                  <CheckIcon className="absolute h-6 w-6 text-orange-500" aria-hidden="true" />
                    <p className="ml-8 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          
          </div>
        </div>

        <div className="relative mt-4 lg:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-1xl font-extrabold text-gray-900 tracking-tight sm:text-2xl">
            Le droit du travail
            </h3>

            <dl className="mt-10">
              {communicationFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                  <CheckIcon className="absolute h-6 w-6 text-orange-500" aria-hidden="true" />
                    <p className="ml-8 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          
          </div>
        </div>

      
      </div>
    </div>
  )
}
