import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpAriane from "../blocs/Ariane"
import KdpActivites from "../blocs/Activites"
import KdpActivites2 from "../blocs/Activites2"

const ariane = [
  { name: 'Les activités', href: '', current: true },
]

export default function Activites() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Les activités de CEOS Avocats</title>
        <meta name="description" content="Droit social, Contentieux judiciaires et administratifs et Formation" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        


        <KdpActivites />
        <KdpActivites2 />




      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}