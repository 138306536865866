/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { ArrowSmRightIcon, CheckIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Droit immobilier : copropriété, baux, ventes immobilières',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 2,
    name: 'Droit de la construction et des assurances',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 3,
    name: 'Droit bancaire et voies d’exécution',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 4,
    name: 'Droit commercial (baux commerciaux, recouvrement de créances, procédures de redressement et liquidation judiciaire) ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 5,
    name: 'Responsabilité médicale ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 6,
    name: 'Réparation du préjudice corporel ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 7,
    name: 'Responsabilité civile professionnelle',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 8,
    name: 'Droit des successions',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'La réforme des procédures d’instruction des demandes de reconnaissance d’accident du travail et des maladies professionnelles entrée en vigueur le 1er décembre 2019',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 2,
    name: 'La faute inexcusable de l’employeur',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 3,
    name: 'Le dispositif de départ en retraite anticipé lié à la pénibilité au travail',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 4,
    name: 'Les règles de la tarification des cotisations AT/MP',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 5,
    name: 'La gestion et les conséquences de l’accident du travail du salarié intérimaire',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 6,
    name: 'Les enjeux et conséquences de l’accident du travail pour l’encadrement de chantier',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 7,
    name: 'La lutte contre le travail illégal et les obligations de vérification par l’entreprise principale du respect de leurs obligations déclaratives et de paiement par ses sous-traitants.',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
]

const communicationFeatures2 = [
  {
    id: 1,
    name: 'Convention Régionale Cadre d’un grand groupe de Travaux Publics (2011) ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 2,
    name: 'Journées Techniques de l’ASEBTP (2003 et 2009)',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 3,
    name: 'Formations dispensées au sein de l’École des Avocats Rhône Alpes : - Déontologie (2007) - Pensions de réversion (2008) ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 4,
    name: 'Formations dispensées au sein de l’Union des Jeunes Avocats de Lyon : - Déontologie (2007 – 2008) - Procédure d’instruction des déclarations de maladies professionnelles et des accidents du travail (2010) - L’accident du travail du salarié intérimaire (2011) - réforme des procédures d’instruction des demandes de reconnaissance d’accident du travail et des maladies professionnelles (2020)',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
  {
    id: 5,
    name: 'Conférence sur la Société Civile Immobilière - La caution du dirigeant (2004) ',
    description:
      '',
    icon: ArrowSmRightIcon,
  },
]

export default function Example() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-8">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

        <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
            width={404}
            fill="none"
            viewBox="0 0 404 4000"
            aria-hidden="true"
          >
          </svg>

        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h2 id="contentieux" className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Contentieux judiciaire et administratif
            </h2>
            <p className="mt-3 text-lg text-gray-500 text-justify">
            Compte tenu de la formation et de l’expérience de ses membres, le cabinet Céos Avocats gère également des contentieux judiciaires et administratifs dans des domaines juridiques ciblés : 
            </p>

            <dl className="mt-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                  <CheckIcon className="absolute h-6 w-6 text-orange-500" aria-hidden="true" />
                    <p className="ml-8 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          
        </div>

        

        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            
              <h2 id="formation" className="mt-24 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Formation</h2>
              <p className="mt-3 text-lg text-gray-500 text-justify">
              Le Cabinet Céos Avocats organise régulièrement des formations privées sur les thèmes relevant principalement de la sécurité au travail et de la gestion des cotisations de sécurité sociale, et notamment sur :
              </p>

              <dl className="mt-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                        <CheckIcon className="absolute h-6 w-6 text-orange-500" aria-hidden="true" />
                      <p className="ml-8 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>

              <p className="mt-10 text-lg text-gray-500 text-justify">
              Le cabinet Céos Avocats enrichit régulièrement les thèmes de ses formations en fonction de l’actualité législative et judiciaire. 
              </p>
              <p className="mt-3 text-lg text-gray-500 text-justify">
              La qualité des formations repose sur le savoir-faire acquis par ses associés en de multiples interventions et notamment : 
              </p>
              <dl className="mt-10">
                {communicationFeatures2.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                        <CheckIcon className="absolute h-6 w-6 text-orange-500" aria-hidden="true" />
                      <p className="ml-8 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>

          </div>
        </div>

       

      
      </div>
    </div>
  )
}
